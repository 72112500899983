import Prismic from '@prismicio/client';
import { LANGUAGE } from '../constants';

export const Client = () => {
  const CLIENT_ENDPOINT = 'https://bokamera.prismic.io/api/v2';
  const client = Prismic.client(CLIENT_ENDPOINT, {
    accessToken: import.meta.env.VITE_PRISMIC_ACCESS_TOKEN
  });

  return client;
};

export const getLanguage = (language) => {
  switch (language) {
    case 'sv':
      return 'sv-se';
    case 'no':
      return 'no';
    case 'en':
      return 'en-us';
    case 'fi':
      return 'fi';
    default:
      return 'sv-se';
  }
};


export const linkResolver = (doc) => {
  
  // URL for a category type
  if (doc.type === 'article') {
    return `/articles/${doc.uid}`;
    
  }

  if (doc.type === 'feature') {
    return `/features/${doc.uid}`;
    
  }

  if (doc.type === 'category') {
    return `/categories/${doc.uid}`;
    
  }


  // URL for a page type
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }
  // Backup for all other types
  return `/${doc.uid}`;
};